@import "../../_globalColor";

.social-media-div {
  font-size: 2em;
}

/* SVG 파일 넣을시 21px , 21px 에다가 테두리를 더하면 42픽셀됨 */
.icon-button {
  margin-bottom: 10px;
}

.icon-button i
{
  color: $textColorDark;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

.icon-button svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  fill: currentColor;
}

.facebook i {
  background-color: $faceBook;
}

.linkedin i {
  background-color: $linkedin;
}

.github i {
  background-color: $github;
}

.gitlab i {
  background-color: $gitlab;
}

.google i {
  background-color: $google;
}

.twitter i {
  background-color: $twitter;
}

.medium i {
  background-color: $medium;
}

.stack-overflow i {
  background-color: $stackoverflow;
}

.kaggle i {
  background-color: $kaggle;
}

.instagram i {
  background-color: $instagram;
}

 .tistory i {
   background-color: #eb531f;   /* 원 테두리 안의 색 */
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
  background-color: $textColor;
}

.tistory i:hover {
  background-color: #000000;  /* 마우스 오버 */
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
